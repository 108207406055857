.card {
  cursor: pointer;
  background-color: #f6f4f5 !important;
  border-radius: 5px;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.widths {
  width: 50%;
   @media screen and (max-width:767px) {
    width: 100%;
   }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #4f5054;
}

.description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px !important;
  color: #4b4a4a;
}

.settings {
  font-size: 22px;
  font-weight: 700;
}

.selectWidth {
  width: 50%;
  @media screen and (max-width:767px) {
    width: 100%;
   }
}

.label{
  width: 40%;
  font-size: 16px;
  font-weight: 600;
  @media screen and (max-width:767px) {
    width: 100%;
   }
}

.grid {
  margin: 5px -5px !important;
  width: 100% !important;
}