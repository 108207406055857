// table

.themeTable {
  padding: 20px;
  border-spacing: 0;

  &.sizesmall {
    padding: 7px 0;
  }

  th {
    padding-bottom: 10px;
    color: #333;
    font-weight: 600;

    &.sortby {
      // color: #777;
      font-weight: 900;
    }

    &.isSortable {
      cursor: pointer;
    }

    .sortIcon {
      color: #777;
      margin-left: 10px;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  td,
  th {
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;

    border: 0;
    border-bottom: 1px solid $primary;
  }

  .sizeBig {
    width: 75%;
  }

  .sizeSmall {
    width: 10%;
  }

  .no_rec_td {
    text-align: center;
    padding: 10px 15px;
  }

  tr {
    margin: 2px;
  }

  tr.disabled {
    color: #333;
  }

  tr {

    th,
    td {
      padding: 5px 0;
      height: 1px;
      cursor: pointer;

      @media screen and (max-width: 640px) {
        height: auto;
        padding: 2px;
        display: flex;
        align-items: center;
      }

      .themeCellContent {
        display: flex;
        background: transparent;
        padding: 3px 2px;
        height: 100%;
        align-items: center;
        overflow-wrap: anywhere;
      }

      &.text-right .themeCellContent {
        justify-content: flex-end;
      }

      &:first-child {
        .themeCellContent {
          border-radius: 4px 0 0 4px;
        }

        // padding-left: 5px;
      }

      &:last-child {
        .themeCellContent {
          border-radius: 0 4px 4px 0;
        }

        padding-right: 5px;
      }
    }

    th {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &.clickable tr:hover td {
    .themeCellContent {
      background: $gray-bg;
    }
  }

  tr.selectedrow td {
    .themeCellContent {
      background: rgba(0, 172, 193, 0.2);
    }
  }

  &.noborder {

    td,
    th {
      border: none;
      padding: 10px 4px;
    }
    // th:first-child {
    //   width: 120px;
    // }

    // th:nth-child(2) {
    //   width: 110px;
    // }

    // th:nth-child(3) {
    //   width: 90px;
    // }

    // // th:nth-child(6) {
    // //   width: 160px;
    // // }
  }

  @media screen and (max-width: 640px) {
    tr.selectedrow {
      background: rgba(0, 172, 193, 0.2);
      td {
     
        .themeCellContent {
          background: transparent;
        }
      }
    }
  }
}

.customtable {
  padding: 20px;
  border-spacing: 0;

  &.sizesmall {
    padding: 25px 0;
  }

  th {
    padding-bottom: 10px;
    color: #333;
    font-weight: 600;
    background-color: #e0e0e0;

    &.sortby {
      // color: #777;
      font-weight: 900;
    }

    &.isSortable {
      cursor: pointer;
    }
  }

  td,
  th {
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 10px;

    border: 0;
    border-bottom: 1px solid $primary;
  }

  .sizeBig {
    width: 75%;
  }

  .sizeSmall {
    width: 10%;
  }

  .no_rec_td {
    text-align: center;
    padding: 10px 15px;
  }

  tr {

    th,
    td {
      padding: 5px 0;
      height: 1px;
      cursor: pointer;

      @media screen and (max-width: 640px) {
        height: auto;
        padding: 2px;
        display: flex;
        align-items: center;
      }
    }

  }
  &.noborder {

    td,
    th{
      border: none;
      padding: 10px;
    }
  }

}

.hideDefaultSortIcon {
  .sortIcon {
    margin-left: 5px;
  }
  .MuiTableSortLabel-icon {
    display: none;
  }
}

.thameSecondaryDialog {
  .MuiPaper-root.MuiDialog-paper {
    min-width: 450px;
  }
}
.MuiDrawer-docked .MuiPaper-root {
  background-color: #343a45 !important;
}

.nl2br {
  white-space: pre-wrap;
}

.menuRightArrowIcon {
  position: absolute;
  right: 0px;
  color: #ffffff66 !important;
  transition: .5s;
}

.menuRightARrowDownIcon {
  transition: .5s;
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.danger-color {
  color: $danger !important;
}

.MenuBadgeIcon {
  position: absolute;
  left: -15px;
  top: -10px;
}

.ChildMenuBadgeIcon {
  position: absolute !important;
  right: 20px;
  top: 10px;

}

.MuiBadge-badge{
  z-index: 0 !important;
}

.no-wrap {
  white-space: nowrap;
}