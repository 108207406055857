.headerWrap {
  background: #343a45;
  width: 100%;
  display: flex;
  @media screen and (max-width:767px) {
    flex-direction: row-reverse;
  }
  * {
    color: #fff;
  }
  img {
    max-width: 220px;
    @media screen and (max-width: 540px) {
      max-width: 150px;
    }
  }
  .logowrap h6 {
    text-transform: uppercase;
  }
  .headerRight {
    margin-left: auto;
    ul {
      display: flex;
      align-items: center;
      li > div{
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    img {
      width: 22px;
    }
  }
}
