.extraheader{

  border-top: 0px solid gray!important;
  border-bottom: 1px solid gray !important;
}

.taskassigned {
    background-color:  #D3D3D3 !important;
  text-align: center !important;
}
