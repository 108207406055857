.pageTitleWrap {
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width:767px) {
    padding-left: 12px; 
  } 
}
.pageTitle {
  margin-bottom: 5px;
  margin-top: 18px;
  color: #3f566a;
  font-size: 20px;
}
.rightTitle{
  font-size: 16px;
  color: #414855;
  strong{
    color: #000;
  }
}
ul.breadcrumbWrap {
  display: flex;
  align-items: center;
  clear: both;
  flex-wrap: wrap;
  li {
    float: left;
    * {
      vertical-align: middle;
    }
    svg {
      width: 22px;
      line-height: 0;
      color: #ced4da;
    }
    a,
    span {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      display: inline-block;
      vertical-align: middle;
      padding: 3px 5px;
      // @media screen and (max-width:767px) {
      //   font-size: 9px;
      // }
    }
    span {
      padding: 3px 0;
    }
    &:first-child {
      a,
      span {
        padding-left: 0;
      }
    }
    .active{
      color: #333;
      font-weight: normal;
    }   
  }
}
