.width25 {
    & {
        width: 15%;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    @media (max-width: 1440px) {
        width: 25%;
    }
}

.border {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.actions {
    color: #4472fd !important;
}

.Action {
    color: #078037 !important;
    align-self: baseline;
}

.popup {
    color: gray !important;
}

.Link {
    color: #9dcaf7 !important;
    font-size: 15px;
    text-decoration: underline !important;
    display: block;
    padding: 2px 4px;
}

.ddminWidth {
    & {
        width: 200px !important;
    }

    @media screen and (max-width:767px) {
        width: 320px !important;
        margin: 0px !important;
    }

    @media (min-width:771px) and (max-width:880px) {
        width: 410px !important;
    }

    @media (min-width:881px) and (max-width:940px) {
        width: 460px !important;
    }
}

.ddminWidth input {
    width: 100% !important;
}

.wdate input {
    & {
        width: 370px !important;
    }

    @media (min-width:771px) and (max-width:940px) {
        width: 530px !important;
    }
}

.wdate2 input {
    & {
        width: 200px !important;
    }

    @media (min-width:771px) and (max-width:940px) {
        width: 91% !important;
    }
}

.linksColumn {
    display: flex;
    flex-direction: column;
}

.box {
    & {
        background-color: white !important;
        padding-bottom: 10px !important;
    }

    @media screen and (max-width:767px) {
        opacity: 1;
        transform: none;
        box-shadow: none;
        transition: opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 163ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        top: 381px !important;
        left: 270px !important;
        transform-origin: 111px 0px;
    }
}

.MuiPaper-root {
    left: 270px !important;
}

.box2 {
    background-color: #D3D3D3 !important;
    padding-left: 5px !important;
    padding-right: 40px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.TaskContent {
    // background-color: #f9f9f9;
    padding: 10px;
    border-top: 1px;

}

.AccordionIcon {
    margin-left: 5px;
    cursor: pointer;
    top: 52px !important;
}

.TaskSummary {
    display: flex;
    align-items: center !important;
    // justify-content: space-between; /* Added to push arrow icon to the right */
    cursor: pointer;
}

.task {
    padding-left: 750px;
    // background-color: #efefef; /* Adjust the padding according to your layout */
}

.themeCellContentt {
    display: flex;
    background: transparent;
    padding: 3px 10px;
    height: 100%;
    align-items: baseline;
    border: none;
}

.accordiann {
    padding-left: 0px;
    margin-bottom: 12px;
}

.report {
    & {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    @media screen and (max-width:767px) {
        justify-content: center;
    }
}

.download1 {
    color: #078037 !important;
    align-items: center;
    display: flex;
}

.sectionbox {
    padding-bottom: 145px !important;
}

.textunderline {
    text-decoration: underline !important;
}

.deltaclr1 {
    color: #0b48ff;
}

.deltaclr2 {
    color: #05500e;
}

.deltaclr3 {
    color: red;
}

.timeZone {
    color: #0b48ff !important;
    text-decoration: underline !important;
}

.header {
    margin-bottom: 20px;
}

.header h3 {
    margin: 10px 0;
}

.Action2 {
    color: #096414 !important;
}

.timeZone2 {
    color: #000 !important;
    text-decoration: underline !important;
}

.listItemm2 {
    padding: 0 !important;
    margin-top: 10px !important;
}

.cardHeader {
    background-color: #d4d4d4 !important;
    height: 50px !important;
}

.listText {
    padding: 10px 15px !important;
}

.supplyTitle {
    font-size: 14px;
    font-weight: 800;
}

.supplyDataTitle {
    width: 70%;
    text-align: left;
}

.supplyTitleStatus {
 width: 27%;
 text-align: left;
 white-space: nowrap;
}
.supplyDataStatus {
 width: 30%;
 text-align: left;
 white-space: nowrap;
}