.card {
    margin-bottom: 20px !important;
    width: 94% !important;
    border: 0.5px solid #3d3d3d !important;
    
    @media (min-width:470px) and (max-width:880px) {
        width: 100% !important;
    }
    @media (min-width:881px) and (max-width:940px) {
        width: 97% !important;
    }
}

.card2 {
    width: 100% !important;
    border: 0.5px solid #3d3d3d !important;

    @media (min-width:470px) and (max-width:940px) {
        width: 100% !important;
    }
}

.card3 {
    margin-bottom: 20px !important;
    width: 370px !important;
    border: 0.9px solid #3d3d3d !important;
    
    @media (min-width:470px) and (max-width:880px) {
        width: 455px !important;
    }
    @media (min-width:881px) and (max-width:940px) {
        width: 555px !important;
    }
}

.card4 {
    width: 370px !important;
    border: 0.7px solid #3d3d3d !important;

    @media (min-width:470px) and (max-width:940px) {
        width: 490px !important;
    }
}

.cardHeader {
    background-color: #efefef !important;
    height: 50px !important;
    font-weight: 400 !important;
}

.cardContent {
    padding: 0px !important;
}

.listItemm {
    padding: 10px 20px !important;
}

.listItemm2 {
    padding: 0 !important;
    margin-top: 15px !important;
}

.listText {
    padding: 8px 15px !important;
    background-color: #efefef !important;
}

.button {
    width: 80px !important;
    border-radius: 10px !important;
}

.divider{
    color: #dedede !important;
}

.button2 {
    width: 100% !important;
    margin-top: 10px !important;
    border-radius: 5px !important;
    height: 40px !important;
    font-size: 20px !important;

    @media (min-width:771px) and (max-width:941px) {
        width: 100% !important;
    }

}

.icons {
    color: #078037;
    width: 40% !important;
    display: flex;
    justify-content: space-around;

    @media (min-width:771px) and (max-width:941px) {
        width: 30% !important;
    }
}

.cardActions {
    justify-content: space-between !important;
    padding: 10px 10px;
}

.cardHeader2 {
    font-size: 1.2rem !important;
    font-weight: bold;
    background-color: #efefef !important;

}

.cardContent2 {
    padding: 20px;
    margin-top: 15px;
}

.dateHeader {
    background-color: #efefef;
    padding: 10px;
    font-weight: 600 !important;
}

.recordRow {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0px 15px;
}

.recordValue {
    font-size: 1.2rem;
    font-weight: 600;
}

.popupData {
    font-size: 0.9rem;
}

.popupItem {
    margin-bottom: 5px;
}

.popupItem span {
    font-weight: 600;
}

.popupItem div {
    padding: 3px 0;
}

.totalRow {
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    text-align: left;
    margin-top: 20px;
    background-color: #d4d4d4 !important;
    padding: 6px 10px;
}

.title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;

}

.title1 {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 5px;
}

.title2 {
    font-size: medium;
    align-self: flex-end;
}

.date {
    font-size: medium;
}

.taskTable {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
}

.taskTable th {
    text-align: left;
    padding: 5px;
}

.taskTable td {
    padding: 5px 5px 15px;
}

.description {
    text-align: center;
}