html body {
  padding: 0 !important;
  margin: 0 !important;
  background-color: $bodyBg;
  font-family: "Raleway", "MarkOT", sans-serif;
  font-weight: normal;
  line-height: 16px;
  color: $fontColor;
  font-size: 14px;
}

img {
  display: block;
  max-width: 100%;
}

h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

.breadcrumbs {
  a:not(.button) {
    text-decoration: none;
    color: #000;

    &:hover,
    &:focus,
    &.active,
    &:active {
      color: $primary;
    }
  }
}

a:not(.button) {
  text-decoration: none;
  color: #fff;

  &:hover,
  &:focus,
  &.active,
  &:active {
    color: $primary;
  }
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.content-wrap {
  min-height: calc(100vh - 133px);
}

.MuiContainer-root {
  @media (min-width:771px) and (max-width:932px) {
    .MuiContainer-root {
      width: 92%;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-width:767px) {
  .MuiContainer-root {
    padding: 5px 0px 5px 0px !important;
    margin: 0 !important;
  }
}

body {
  .bg-alert {
    background-color: $alert !important;
    color: #ffffff;
  }

  .bg-secondary {
    background-color: $secondary !important;
    color: #ffffff;
  }

  .bg-danger {
    background-color: $danger !important;
    color: #ffffff;
  }

  .bg-gray {
    background-color: #e0e0e0;
  }

  .bg-white {
    background-color: #ffffff;
  }

  .color-alert {
    color: $alert !important;
  }

  .color-badge {
    color: $badgeBg !important;
  }

  .color-secondary {
    color: $secondary !important;
  }

  .color-danger {
    color: $danger !important;
  }

  .color-primary {
    color: $primary;
  }

  .color-white {
    color: #fff !important;
  }

  .color-black {
    color: #000 !important;
  }

  .color-orange {
    color: $orange-color;
  }

  .color-gray {
    color: $gray-color;
  }

  .color-blue {
    color: $blue-color;
  }

  .color-Darkblue {
    color: $blue-dark-color !important;
  }
  .color-red {
    color: red ;
  }

  .d-flex {
    display: flex;
  }

  .flex1 {
    flex: 1;
  }

  .d-block {
    display: block;
  }

  .d-none {
    display: none;
  }

  .d-inline {
    display: inline;
  }

  .d-inline-block {
    display: inline-block;
  }

  .flex-center {
    align-items: center;
  }

  .flex-start {
    align-items: flex-start;
  }

  .flex-end {
    align-items: flex-end;
  }

  .self-end {
    align-self: flex-end;
  }

  .self-start {
    align-self: flex-start;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-space-between {
    justify-content: space-between;
  }

  .flex-space-evenly {
    justify-content: space-evenly;
  }

  .flex-space-around {
    justify-content: space-around;
  }

  .flex-justify-center {
    justify-content: center;
  }
  .flex-justify-start {
    justify-content: flex-start;
  }

  .flex-justify-end {
    justify-content: end;
  }

  .flex-justify-flexend {
    justify-content: flex-end;
  }

  .flex-direction-row {
    flex-direction: row;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-direction-rowReverse {
    flex-direction: row-reverse;
  }

  .mw-auto {
    min-width: auto !important;
  }

  .w-60 {
    min-width: 60px;
  }
  .w60 {
    width: 60%;
  }

  .border-bottom {
    border-bottom: 1px solid $primary;
  }

  .border-right {
    border-right: 1px solid $primary;
  }

  .border-top {
    border-top: 1px solid $primary;
  }

  .border-left {
    border-left: 1px solid $primary;
  }

  .MuiButton-root {
    text-transform: none !important;
  }

  .MuiIconButton-root:hover {
    background-color: initial;
  }

  .w-100 {
    width: 100%;
  }

  .w-45 {
    width: 45%;
  }

  .w-35 {
    width: 35%;
  }

  .w-25 {
    width: 25%;

    @media screen and (max-width:940px) {
      width: 100% !important;
      margin-top: 10px;
    }
  }

  .w-30 {
    width: 30%;

    @media screen and (max-width:767px) {
      width: 100% !important;
      margin-bottom: 10px !important;
    }
  }

  .w-65 {
    width: 65%;
  }


  .time-list {
    display: flex;
    flex-direction: column;
  }

  .time-option {
    margin: 5px 0;
  }

  .calendarHeader {
    background-color: #888888;
    color: white;
    padding: 15px 20px;
  }

  .radiotimes {
    height: 340px;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-wrap: nowrap;
    color: #999999;
    padding: 5px;
    scroll-behavior: smooth;
    // scrollbar-width: none; 
  }
//   .radiotimes::-webkit-scrollbar {
//     display: none; /* Hides scrollbar in Webkit-based browsers */
// }
  label {
    scroll-margin: 5px;
  }

  .w-20 {
    @media screen and (max-width:1450px) {
      width: 20%;
    }

    @media (min-width:1451px) {
      width: 18%;
    }

    @media screen and (max-width:767px) {
      width: 60% !important;
      font-size: 12px !important;
      text-align: end !important;
    }
  }

  .w-185 {
    width: 185px !important;
  }

  .mt-neg {
    margin-top: -20px !important;
  }

  .buttonRess {
    width: 175px !important;
    font-size: "22px" !important;
    border-radius: 2px;
  }

  .h-stretch {
    height: 100%;
  }

  .h-stretch8 {
    height: 90%;
  }

  .h-stretch9 {
    height: 94%;
  }

  .fullwidth {
    display: block;
    width: 100%;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-underLine {
    text-decoration: underline !important;
  }

  .fleft {
    float: left;
  }

  .fright {
    float: right;
  }

  .fw-bold {
    font-weight: bold;
  }

  .fw-600 {
    font-weight: 600;
  }

  .strong {
    font-weight: 500;
  }

  .scrollable {
    max-height: 210px;
    overflow-y: auto;
  }

  @media screen and (max-width:767px) {
    .editEmp {
      flex-direction: column !important;
    }

    .issueicon {
      align-self: flex-start !important;
    }

    .issueWrap {
      overflow-wrap: break-word;
    }

  }

  @media (min-width:771px) and (max-width:940px) {
    .issueAlign {
      flex-direction: column !important;
    }

    .issuee {
      align-self: flex-start !important;
    }

    .reports {
      flex-direction: column !important;
      // width: 50%;
      align-items: center;
    }
  }
}

.clientsingleSelect {
  width: 150px !important;

  input {
    width: 100% !important;
  }
}

.reportsSingleSelect {
  width: 150px !important;

  @media screen and (max-width:767px) {
    width: 305px !important;
  }

  input {
    width: 100% !important;
  }
}

.reportsMargin {
  @media (min-width:1000px) {
    margin-left: 10px;
  }
}

.width50 {
  width: 50%;

  @media screen and (max-width:940px) {
    width: 100%;
  }
}

.customDatePicker input {
  width: 130px !important;
  height: 28px !important;

  @media screen and (max-width:469px) {
    height: 40px !important;
    margin: 0 !important;
    padding: 0 10px !important;
    box-sizing: border-box !important;
    width: 355px !important;
  }

  @media (min-width:470px) and (max-width:880px) {
    width: 465px !important;
  }

  @media (min-width:881px) and (max-width:940px) {
    width: 555px !important;
  }
}

.customDatePicker label {
  top: -8px !important;
}

.customSearch input {
  height: 30px !important;
}

.customSearch label {
  color: #000 !important;
}

.delete {
  @media screen and (max-width:767px) {
    flex-direction: column;
  }
}

.wNew-50 {
  width: 50%;

  @media screen and (max-width: 767px) {
    width: 385px !important;
    align-items: flex-start !important;
  }
}

.emailRes {
  @media screen and (max-width:767px) {
    margin-left: -55px !important;
  }
}

@media (min-width:1000px) {
  .statuswidth {
    width: 120px !important;
  }

  .shiftWidth {
    width: 130px !important;
  }

  .notifWidth1 {
    min-width: 110px !important;
  }

  .notifWidth2 {
    min-width: 245px !important;
  }
}

.viewLinks {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 15px;
  text-align: center !important;
  width: 100%;
}

.appointments-container {
  @media screen and (max-width:767px) {
    flex-wrap: wrap;
  }
}

.appointment-card {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid red;
  border-radius: 3px;
  margin-right: 20px;
  width:130px;
}

.client-name {
  font-size: 18px;
  font-weight: 800;
  color: #3d3d3d;
  margin: 3px 0;
  word-break: break-all;
}

.appointment-date {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  margin: 3px 0;
}

@media (max-width: 768px) {
  .appointment-card {
    width: calc(50% - 20px);
    margin-bottom: 15px;
  }
}

.notifications {
  display: grid;

  @media screen and (max-width:767px) {
    display: none;
  }
}

.notification1 {
  display: none;

  @media screen and (max-width:767px) {
    display: grid;
    height: 170px;
  }
}

.MuiTypography-h5 {
  & {
    font-size: 1.2rem !important;
    font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600 !important;
    line-height: 1.334;
  }

  @media screen and (max-width:940px) {
    color: #3d3d3d !important;
  }
}

.MuiTypography-body1 {
  & {
    font-size: 1rem !important;
    font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500 !important;
    line-height: 1.5;
  }

  @media screen and (max-width:940px) {
    color: #3d3d3d !important;
  }
}

.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 420 !important;
  line-height: 1.43;
}

.MuiCardHeader-root {
  display: flex;
  padding: 12px !important;
  align-items: center;
}

.empSummary {
  padding: 12px 15px 12px 0px !important;
}

.maxWidth {
  max-width: 100% !important;
}

.ddminWidths {
  width: 100px !important;

  @media screen and (max-width:767px) {
    width: 150px !important;
  }

  @media (min-width:770px) and (max-width:940px) {
    min-width: 200px !important;
  }
}

/* Hide for all devices by default */
.customDate {
  display: none;
}

/* Show only on iOS devices (iPhones, iPads, and iOS-based laptops) */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  @supports (-webkit-touch-callout: none) {
    .customDate {
      display: block;
    }

    .customDate input {
      opacity: 1;
      display: block;
      background: url("../images/dateTime.png") no-repeat;
      border-width: thin;
      background-repeat: no-repeat;
      background-position: 98%;
      margin-right: 10px;
    }

    input::-webkit-date-and-time-value {
      text-align: left;
      text-align: -webkit-left;
    }
  }
}

.timeWidth {
  min-width: 310px;

  @media screen and (max-width:469px) {
    min-width: 368px !important
  }

  @media (min-width:470px) and (max-width:880px) {
    width: 468px !important;
  }

  @media (min-width:881px) and (max-width:940px) {
    width: 455px !important;
  }
}

tr.border-class td {
  border-top: 2px solid #e0e0e0 !important;
}

.supplysWidth h4 {
  width: 20% !important;
}

.gridBorder {
  border-bottom: 1px solid #000 !important;
}

.supplysWidth p {
  width: 20% !important;
}

.noBorder tbody tr {
  border: none;
}

@media screen and (max-width:940px) {
  .MuiCardContent-root {
    padding: 0 !important;
  }
}

.badge {
  background: $badgeBg;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 5px;
  border-radius: 4px;
  vertical-align: text-top;
  text-transform: none;
  display: inline-block;
}

.button {
  background-color: $primary;
  color: #ffffff;
  box-shadow: none;
  border: none;
}

button.flatbutton {
  box-shadow: none;
  border: none;
  border-radius: 0;
  min-width: auto;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  &.w-norm {
    width: 200px;

    @media screen and (max-width:767px) {
      width: 100%;
    }
  }

  @media (max-width: 431px) {
    height: 31px;
  }
}

button.button-danger {
  background: $danger;
  color: #ffffff;

  @media screen and (max-width:940px) {
    padding: 8px;
  }
}

button.linkbutton {
  background: transparent;
  color: $blue-color;
  padding: 0;
  text-decoration: underline;
}

.menulist {

  &,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    position: relative;
    padding: 0 0px;

    @media screen and (max-width: 600px) {
      display: block;
    }

    .menulink.MuiButton-root {
      min-width: auto;
    }

    .menulink {
      padding: 0;
      background: none;
      text-transform: none;
    }

    &.active {

      &,
      a {
        color: $primary;
      }
    }

    &:not(:first-child):after {
      content: "";
      position: absolute;
      left: 0;
      height: 12px;
      background: #fff;
      width: 1px;
      top: 0;
      bottom: 0;
      margin: auto;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  &.footermenu li a {
    font-size: 12px;
    color: $secondary;
  }
}

@media screen and (max-width:767px) {
  .containerGrid {
    display: block !important;
  }
}



.containerSpacing {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loaderCSS {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.sectionBox {
  background-color: $wrap-bg;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px;
  position: relative;

  &.nospacing {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.pad_0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.radius {
    border-radius: 4px;
  }

  &.shadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
}

.sectionBox2 {
  background-color: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px;

  @media screen and (max-width:940px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  &.nospacing {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.pad_0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.radius {
    border-radius: 4px;
  }

  &.shadow {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
}

.dashBoardCustom {
  @media screen and (max-width:767px) {
    padding: 0px 10px;
    box-sizing: border-box !important;
  }
}

.clear {
  clear: both;
  display: block;

  &:before,
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

.formFieldGroup {

  label,
  input {
    padding: 0 12px;
  }

  .input {
    border: 1px solid #e9ecf0;
    border-radius: 4px;
    box-shadow: none;
    padding: 0;
    margin: 5px 0;

    &:before {
      display: none;
    }

    input {
      padding: 6px 12px 7px;
      margin: 0;
    }
  }
}

.BlankContainer,
.DefaultContainer {
  min-height: 250px;
}

.loginwrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 355px;
  max-width: 90%;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  padding: 15px 20px;
  overflow-y: auto;
  max-height: 94%;

  .cmslogo {
    padding: 0 15px;
  }

  @media (max-height: 350px) {
    transform: translate(-50%, 0);
    top: 0;
  }

  @media (max-width: 350px) {
    transform: translate(0, -50%);
    left: 0;
  }

  @media (max-height: 350px) and (max-width: 350px) {
    transform: translate(0, 0);
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
  }
}

.loginbottom {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.input {
  border: 1px solid #e9ecf0;
  border-radius: 4px;
  box-shadow: none;

  &:before {
    display: none;
  }

  input {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 12px;
  }

  .input-adornment {
    background: #f8f9fa;
    border: none;
    border-right: 1px solid #e9ecf0;
    padding: 11px 9px;
    line-height: 0;
    display: block;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 38px;
  }

  .MuiInputAdornment-root {
    background: #f8f9fa;
    height: 100%;
    border: none;
    border-right: 1px solid #e9ecf0;
    padding: 5px 5px 5px 5px;
    line-height: 1;
    display: block;
    position: relative;
    top: 0;
    bottom: 0;
  }
}

.select-input select {
  padding: 10px 12px;
}

.nameProfile {
  text-transform: capitalize;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 600;
  text-align: right;
  color: white;
  text-decoration: underline;
}

.usersWrap {
  display: flex;
  align-items: center;
  padding: 20px 15px 10px;

  .userImg {
    margin-right: 15px;
    line-height: 0;
  }

  .userInfo {
    cursor: pointer;

    .name {
      text-transform: capitalize;
      font-size: 14px;
      font-family: "Raleway";
      font-weight: 600;
      text-align: right;
    }

    .MuiTypography-caption {
      font-size: 12px;
      color: $gray-color;

      @media screen and (max-width:400px) {
        font-size: 7px;
      }
    }
  }

  svg {
    vertical-align: middle;
  }
}

.userMenuWrap {
  width: 140px;

  li {
    padding: 0;
    text-align: left;
    font-size: 14px;

    a {
      display: block;
      padding: 10px 15px;

      &:hover {
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
      }
    }
  }
}

.svg-icon {
  vertical-align: middle;
}

.MuiMenu-list {
  background-color: #fff;
}



.sidebarHeight {
  height: 92vh;
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
  @media (min-width: 1620px) {
    height: 94vh;
  }
}

/* Safari specific styles */
@supports (-webkit-overflow-scrolling: touch) {
  .sidebarHeight {
    height: 89vh;
  }
}


ul.sidebarMenuWrap {
  // overflow-x: auto;
  // max-height: calc(100% - 135px);
  // padding-left: 13px;
  // padding-right: 12px;
  padding-bottom: 30px;
  border-top: 1px Solid #ffffff22;

@media screen and (max-width:767px) {
    margin-top: 50px;
  }
  &>li {
    margin-bottom: 10px;
  }

  li {
    padding: 0;
    display: block;

    a {
      padding: 10px 15px;
      display: flex;
      font-weight: 600;
      align-items: center;
      width: 100%;

      svg {
        margin-right: 10px;
        color: #fff;
      }

      &.button svg {
        color: #ffffff;
      }

      &.active:not(.button) {
        color: $primary;

        svg {
          color: $primary;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
      }
    }

    .submenuitems {
      display: block;
      width: 100%;
      // padding-left: 35px;
      padding-top: 0;
      padding-bottom: 0;

      li>a {
        padding: 5px 15px;
        display: block;
        position: relative;
        width: 100%;
        font-weight: 400;
        padding-left: 50px;

        &.active {
          color: $primary;
        }

        &:hover {
          background: $gray-light-color;
          color: $white;
        }

        // &::before {
        //   position: absolute;
        //   left: -3px;
        //   top: 0;
        //   bottom: 0;
        //   height: 1px;
        //   width: 4px;
        //   content: "";
        //   display: block;
        //   margin: auto;
        //   background: #fff;
        // }
      }
    }
  }

  .innerChildItemTitle {
    display: flex;

    >a {
      padding-right: 5px;
      padding-left: 5px;
      position: relative;
      // &::before {
      //   position: absolute;
      //   left: -3px;
      //   top: 0;
      //   bottom: 0;
      //   height: 1px;
      //   width: 4px;
      //   content: "";
      //   display: block;
      //   margin: auto;
      //   background: #000;
      // }
    }
  }
}
.expanded {
  @media (min-width:768px) {
    width: 240px !important;
  }
}

.collapsed {
  @media (min-width:768px) {
  width: 0px !important;
}
}
.chatBoardContainer {
  padding: 15px;
}

.tabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 2.5px solid #eaeaea;
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 0px 5px;
}

.tabItem {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  color: #333;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}

.tabItem:hover {
  border-bottom: 2px solid $primary;
}

.activeTab {
  border-bottom: 2px solid $primary;
  color: $primary;
}

.channelGroup {
  margin-top: -10px;
}



.sectiontitle {
  color: #414855;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 431px) {
    font-size: 15px;
    font-weight: 700;
    padding: 5px;
  }
}

.clientWrap {
  @media (min-width: 771px) and (max-width: 940px) {
    overflow: auto;
  }
}

.paddings {
  @media screen and (max-width:767px) {
    padding: 0 !important;
  }
}

.clientWidth {
  @media screen and (max-width: 400px) {
    max-width: 320px;
    padding: 0;
    margin: 0;
  }

  @media (min-width:401px) and (max-width: 767px) {
    max-width: 425px;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 771px) and (max-width: 940px) {
    max-width: 560px;
    padding: 0;
    margin: 0;
  }
}

.theameColors {
  color: #2a2a2a !important;
}

.theameVColors {
  color: #636363 !important;
}

.clientsummry {
  @media screen and (max-width:"940px") {
    width: 50% !important;
  }
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}

.clientTasklink {
  color: $blue-color;
  text-decoration: underline !important;
}

.sectiontitlenew {
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0;

  @media screen and (max-width:767px) {
    font-weight: 450;
  }
}

.ntop {
  margin-bottom: 30px;
}

.sectiontitlenew.center {
  margin-bottom: 24px;
  text-align: center;
}


.sectiontitleText {
  @media screen and (max-width:767px) {
    text-align: center;
  }
}

.space-top {
  margin: 20px 0;
}

.stepformwrap {
  margin: 10px 0;

  .MuiAccordion-root.Mui-disabled {
    background: none;
  }

  .stepContent {
    padding: 15px 10px;
    width: 50%;
    max-width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media (min-width: 771px) and (max-width: 940px) {
      width: 100% !important;
    }

    &.w-100 {
      width: 100%;
    }

    .stepSectionTitle {
      color: adjust-color(#707070, $lightness: -10%);
      font-size: 15px;
      font-weight: 600;
      margin: 0 0 15px;
      padding-bottom: 12px;
      border-bottom: 1px solid adjust-color(#e5e5e5, $lightness: -5%);

      svg {
        vertical-align: initial;
      }
    }
  }


  .w-50 {
    & {
      width: 50%;
      max-width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .step {
    padding: 15px 10px 5px;

    .stepTitle {
      cursor: pointer;
      background-color: #707070;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      padding: 10px;
      color: #fff;
      display: flex;
      margin: 0;

      .right {
        margin-left: auto;
        color: #dedede;
      }
    }

    .clear {
      clear: both;
    }

    .fleft {
      float: left;
    }

    .fright {
      float: right;
    }

    .formFieldGroup {
      margin-bottom: 5px;
    }

    &:not(.activeStep) {
      .stepTitle {
        background-color: transparent;
        color: #e5e5e5;
        border-top: 1px solid #e5e5e5;
      }

      .stepContent {
        display: none;
      }
    }

    &.complated {
      .stepTitle {
        background-color: #078037;
        color: #ffffff;

        .right {
          color: #ffffff;
        }
      }
    }

    .error {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .buttonsWrap {
    margin-top: 30px;

    button {
      margin-right: 12px;
    }
  }
}

.file-uploader {
  display: flex;

  button {
    margin-left: 12px;
  }
}

.issuesPadding input {
  padding: 8px !important;
}

.issuesPadding {
  max-width: 50% !important;

  @media screen and (max-width:767px) {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
  }
}

.formFieldwrap {
  margin-bottom: 8px;
  // .MuiOutlinedInput-input,input{

  // }
  &.withButton {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;

    button {
      margin: 0 5px;
    }
  }
}

.alertselect {
  @media screen and (max-width:767px) {
    margin: 0 !important;
  }
}

.groupsearch {
  input {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width:767px) {
      width: 175px !important;
    }

    @media (min-width:770px) and (max-width:940px) {
      width: 150px;
    }
  }
}

.groupsearch2 {
  input {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width:767px) {
      width: 125px;
    }
  }
}

.groupsearch3 {
  input {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width:767px) {
      width: 325px;
    }

    @media (min-width:771px) and (max-width:940px) {
      width: 420px;
    }
  }
}

.fc .fc-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fc .fc-button {
  background-color: transparent;
  border: none;
  color: black;
  padding: 8px 16px;
  border-radius: 4px;
  border: 0.5px solid gray !important;
  outline: none !important;
}

.fc .fc-col-header-cell-cushion {
  color: $primary !important;
}

.fc .fc-daygrid-event {
  background-color: #52be80;
  color: #52be80 !important;
  padding: 4px 6px;
  border-radius: 4px;
}

.fc .fc-daygrid-event:hover {
  background-color: #45a069;
}

.fc .fc-daygrid-day-number {
  color: #000 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #078037;
}

.fc .fc-prev-button,
.fc .fc-next-button {
  padding: 0.5rem !important;
  width: 32px !important;
  background-color: transparent !important;
  color: black !important;
  height: 32px !important;
  border: none !important;
  box-shadow: none !important;
}

.fc .fc-prev-button:focus,
.fc .fc-next-button:focus,
.fc .fc-prev-button:hover,
.fc .fc-next-button:hover,
.fc .fc-prev-button:active,
.fc .fc-next-button:active {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.fc .fc-prev-button:not(:disabled):active,
.fc .fc-next-button:not(:disabled):active {
  background-color: transparent !important;
  border-color: transparent !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc-button:focus,
.fc-button:hover {
  background-color: #078037 !important;
  outline: none !important;
  box-shadow: none !important;
}

.fc .fc-daygrid-event {
  background-color: transparent !important;
  color: black !important;
  padding: 4px 6px;
  border-radius: 0px !important
}

.fc-daygrid-event {
  font-size: 14px !important;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row !important;
}

.fc-event-title:contains('Appts') {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: bold;
  color: green;
}

.fc .fc-toolbar-title {
  font-size: 26px !important;
  margin: 0px;

  @media screen and (max-width:1000px) {
    font-size: 18px !important;
    margin: 0px;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 500 !important;
  min-width: 0px;
  overflow: hidden;
  color: #707070 !important;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 10px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.fc .fc-bg-event {
  background: transparent !important;
  opacity: 1 !important;
  color: #078037 !important;
  display: flex;
  flex-direction: row-reverse;
}

.fc-daygrid-event-dot {
  border: none !important;
  border-radius: 0px !important;
  box-sizing: border-box !important;
  margin: 0px 0px !important;
}

.fc-h-event .fc-event-main {
  color: #000 !important;
}

.fc-h-event {
  border: none !important;
}
.fc-timeGridWeek-view .fc-col-header-cell {
  background-color: #f3f4f6;
  color: #333;
  font-weight: bold;
}
/* Hide the background event's title if it conflicts with actual appointments */
.fc-event.fc-background .fc-event-title {
  display: none;
}

/* Ensure appointments (not background events) show their title */
.fc-event:not(.fc-background) .fc-event-title {
  display: block;
}
.fc-timeGridWeek-view .fc-timegrid-slot-label {
  color: #888;
  font-size: 0.9em;
  padding: 5px;
}

/* Events Background in Weekly View */
.fc-timeGridWeek-view .fc-event {
  background-color: #078037;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px;
}

.fc-timeGridWeek-view .fc-event:hover {
  background-color: #1e6a92;
}

/* Background color for all-day events */
.fc-daygrid-event {
  background-color: rgba(41, 128, 186, 0.2);
  color: #333;
  border: none;
  font-weight: bold;
}

/* Styling for selected day background in dayGrid view */
.fc-daygrid-day-frame.fc-day-today {
  background-color: #f0f8ff;
  border-radius: 4px;
  border: 1px solid #2980ba;
}
.fc-day-today {
  background-color: white !important;
}

.fc-v-event {
  background-color: #078037 !important;
  border: 1px solid #078037 !important;
  display: block;
}

// /* Hide time in the "All Day" area if not needed */
// .fc-timegrid-event.fc-event .fc-event-time {
//   // display: none;
// }

/* Customize the appearance of the date headers */
.fc .fc-col-header-cell-cushion {
  font-size: 1em;
  font-weight: bold;
  color: #555;
}
.fc-timegrid-event {
  position: relative;
  margin-bottom: 4px;
  padding: 5px;
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  font-size: 12px;
}

/* Adjust spacing for weekly view rows and columns */
.fc-timeGridWeek-view .fc-scrollgrid-sync-inner {
  padding: 0px 5px;
}

.fc-timeGridWeek-view .fc-timegrid-slot {
  height: 40px;
}

.fc .fc-daygrid-day-number {
  font-size: 1em;
  color: #2980ba;
}

.modal-overlay.active {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000055;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  z-index: 1000;
  @media screen and (max-width:767px) {
    justify-content: center;
  }
  @media (min-width:1951px) {
    justify-content: center;
  }
}

.modal-content {
  position: fixed;
  background: #fff;
  left:0px;
  right:0px;
  height: 100%;
  // bottom:0px;
  max-height: 70vh;
  z-index: 999;
  padding: 0px 15px 10px 15px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  animation: slide-up 0.3s ease-out;
  overflow-y: auto;
  border-radius: 20px 20px 0 0;  
  @media screen and (max-width:767px) {
    max-height: 84vh;
  }
  @media (min-width:1441px) {
    max-height: 50vh;
  }
}

.MuiDrawer-paper{
  z-index: 99 !important;
}
.modal-content2 {
  position: fixed;
  background: #fff;
  left:0px;
  right:0px;
  height: 100%;
  // bottom:0px;
  max-height: 67vh;
  z-index: 999;
  padding: 0px 15px 10px 15px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  
  overflow-y: auto;
  border-radius: 20px 20px 0 0;
  @media screen and (max-width:767px) {
    max-height: 84vh;
  }

  @media (min-width:1441px) {
    max-height: 50vh;
  }
}
.stickyHeader {
  position: sticky;
  z-index: 1000;
  width: 100%;
  top: 0px;
  background-color: white;
  padding-top: 5px;
}
.slideUpModal {
  animation: 0.3s slide-up-modal;
}

.slideDownModal {
  animation: 0.3s slide-down-modal;
}

@keyframes slide-up-modal {
  from {
    bottom:-67vh;
  }
  to {
    bottom:0px;
  }
}

@keyframes slide-down-modal {
  from {
    bottom:0px;
  }
  to {
    bottom:-67vh;
    
  }
}

// .modal-content2::-webkit-scrollbar {
//   width: 1px;
// }

// .modal-content2::-webkit-scrollbar-track {
//   background: transparent;
//   border-radius: 0 20px 0 0;
// }

// .modal-content2::-webkit-scrollbar-thumb {
//   background: #ddd;
//   border-radius: 4px;
// }
.modal-container {
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5em;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.close-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 1rem;
}

.reschedule-button {
  background-color: #22c55e;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}


.appointment-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.date-icon {
  width: 64px;
  height: 65px;
  background-color: #fff;
  color: black;
  border: 8px solid green;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.date-icon-top-lines {
  position: absolute;
  top: -20px;
  display: flex;
  gap: 4px;
}

.line {
  width: 14px;
  height: 14px;
  background-color: green;
  border-radius: 4px;
  position: relative;
}

.inner-line {
  width: 4px;
  height: 10px;
  background-color: white;
  position: absolute;
  top: 3px;
  left: 5px;
  border-radius: 1px;
}

.date-icon-day {
  font-size: 24px;
  font-weight: bold;
}

.date-icon-month {
  font-size: 18px;
  padding-top: 8px;
  text-transform: uppercase;
}

.appointment-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 3px 0;
}

.appointment-datetime {
  color: #666;
  font-size: 18px;
  line-height: 1.2;
}

.details-grid {
  display: flex;
  gap: 1.5rem;
  @media screen and (max-width:767px) {
    display: flex;
    flex-direction: column;
  }
}

.details-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-column.right {
  border: 2.5px solid #078037;
  border-radius: 4px;
  padding: 18px;
  background-color: #f0f0f0;
  width: 40%;
  @media screen and (max-width:767px) {
    width: 100%;
  }
}

.detail-item {
  margin-bottom: 1rem;
}

.detail-label {
  font-size: 16px;
  color: #a9a8a8;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.detail-label2 {
  font-size: 16px;
  color: #3c3c3c;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.detail-value {
  color: #414141;
  font-size: 16px;
  font-weight: 500;
}

.detail-value2 {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 600;
}

.appointments-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  padding: 10px 5px;
  height: 95px;
  overflow: auto;
}

.appointment-item {
  display: flex;
  align-items: center;
  padding: 2px 0px;
}

.appointment-dot {
  width: 8px;
  height: 8px;
  background-color: #078037;
  border-radius: 50%;
}
.w90 {
  @media (min-width:1000px) {
    width: 90px !important;
  }
}

.w320 {
  input {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    margin: 0 !important;
  }

  @media screen and (max-width:767px) {
    width: 385px;
  }

  @media (min-width:771px) and (max-width:940px) {
    width: 492px;
  }
}

.w350 {
  input {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 100%;
    margin: 0 !important;
  }

  @media screen and (max-width:767px) {
    width: 375px;
  }

  @media (min-width:771px) and (max-width:940px) {
    width: 462px;
  }
}

.formFieldwrap .checkboxfieldwrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  .checkboxfieldlabel,
  .checkboxGroupwrap .MuiTypography-root {
    font-size: 12px;
    line-height: 14px;
    color: #000;
  }

  .checkboxfieldlabel {
    width: 120px;
    margin-top: 5px;
    max-width: 100%;
  }

  .checkboxGroupwrap {
    .MuiCheckbox-root {
      padding: 4px;
    }
  }

  .smallcheckbox {
    padding: 4px;
  }
}

.checkboxGroupwrap {
  .chkboxItemGroup.customtask {
    label {
      color: $orange-color;
    }
  }

  &.colmn-3 {

    .MuiFormGroup-root>label,
    .MuiFormGroup-root>.chkboxItemGroup {
      min-width: 150px;
      width: 32%;

      @media (max-width: 639px) {
        width: 100%;
      }
    }
  }

  &.column-2 {

    .MuiFormGroup-root>label,
    .MuiFormGroup-root>.chkboxItemGroup {
      width: 49%;
      min-width: 210px;

      @media (max-width: 639px) {
        width: 100%;
      }
    }
  }
}

.logoImgPreview {
  max-width: 230px;
  margin: 2px 0;
}

.shiftSummary,
.Summary {
  background-color: #f1f1f1;
  padding: 6px 12px;
}

.summaryList,
.shiftList {

  .summaryListItem,
  .shiftListItem {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .status {
      width: 40px;
    }

    .taskGroupname,
    .shiftname {
      width: 150px;
    }

    .shiftday {
      width: 200px;
    }

    .tasks {
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.taskGroupSelect {
  width: 300px;
}

.newTaskFieldsWrap {
  display: flex;
  align-items: center;

  .taskname,
  .taskdescription {
    margin: 0 12px;
  }

  .taskname {
    width: 160px;
  }

  .taskdescription {
    width: 400px;
  }

  @media (max-width: 639px) {

    .taskname,
    .taskdescription {
      width: 100%;
    }
  }
}

.themebadge {
  padding: 5px 12px;
  background: $primary;
  margin: 10px 3px 0;
  display: inline-block;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
}

.editShift {
  padding: 20px;
}

.timefielditem {
  width: 130px;
}

.icbutton {
  svg {
    display: block;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }
}

.RequestDetailsWrap {
  background: #e0e0e0;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  h4,
  p {
    margin: 5px 0;
  }
}

.RequestDetailsWrapResponsive {
  background: #f1f1f1;

  h4 {
    margin: 5px 0;
  }

  p {
    margin: 5px 0;
    color: #828282;
    font-size: 14px;
  }
}

.noteFormWrap {
  display: flex;
  justify-content: center;
  align-items: stretch;

  .formFieldwrap {
    margin: 0;
  }

  button {
    width: 50px;
    margin-left: 10px;
    border-radius: 4px;
    line-height: 18px;

    .MuiButton-label {
      display: flex;
      flex-direction: column;
    }
  }
}

.scrollelementtop {
  scroll-margin: 100px;
}

.requestNotePopup {
  width: 640px;
  max-width: 100%;
}

.notelistitem {
  .date {
    width: 120px;
    margin-right: 15px;
    text-align: center;
  }
}

.circularprogress {
  .actual .MuiCircularProgress-svg {
    transform: rotate(180deg);
  }

  &.color-warning {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $alert;
    }
  }

  &.color-danger {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $danger;
    }
  }

  &.color-success {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $primary;
    }
  }

  &.color-blue {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $blue-dark-color;
    }
  }

  &.color-bluese {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: $secondary;
    }
  }

  & .MuiTypography-root {
    font-weight: bold;
  }
}

.ulhide li:nth-child(n + 3) {
  border-bottom: none !important;
}

.ulhide li:nth-child(n + 4) {
  display: none;
}

.cms-logo {
  display: block;
  // margin-top: 10px;
  // margin-left: -22px;

  @media screen and (max-width:767px) {
    margin-top: 0;
    align-self: flex-end;
    height: 35px;
    width: 150px;
  }
}

//.ulhideis li:nth-child(n+3){border-bottom: none !important;}
.ulhideis li:nth-child(n + 4) {
  display: none;
}

.greencol {
  color: #078037;
  margin-right: 2px;

  width: 0.8em !important;
  height: 0.8em !important;
}

.ulhide>div:nth-child(n + 4) {
  display: none;
}

.position-relative {
  position: relative;
}

.descol {
  color: #2e2e2e;
  font-size: 15px !important;
  font-weight: 850 !important;
}

.paddtop {
  padding-top: 10px !important;
}

.posti {
  top: 5px;
  text-align: center;
}

.leftpadd {
  padding-left: 20px;
  font-size: 13px;
  font-family: "Raleway";
  color: #595959;
  line-height: 1.2;
  font-size: 0.775rem !important;
}

.fonnotif {
  font-size: 14px !important;
  line-height: 1.2;
  margin-bottom: 12px !important;
}

.posrela {
  position: relative;
  color: #b9b9b9;
}

.iconcol {
  color: #b9b9b9 !important;
  margin-top: 4px;
}

.iconcoln {
  margin-top: 4px;
}

.alertm {
  margin-top: 12px !important;
  padding-bottom: 12px !important;
  border-bottom: 1px solid #dbdbdb;
  font-size: 14px;
  line-height: 20px;
}

.nopadd {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ulhide .alertm:nth-child(n + 3) {
  border-bottom: none !important;
}

.alertm .mad {
  font-size: 16px;
  font-family: "Raleway";
  color: #272727;
  font-weight: 600;
  line-height: 1.2;
}

.MuiDayCalendar-header,
.MuiDayCalendar-weekContainer {
  justify-content: space-between !important;
}

.MuiPickersCalendarHeader-root {
  padding-left: 10px !important;
  padding-right: 0px !important;
}


.capit {
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #272727 !important;
}

.capitis {
  font-size: 14px !important;
  color: #3e3e3e !important;
}

// .themeTable tbody tr:nth-child(even) {background: #efefef}
// .themeTable tbody tr:nth-child(odd) {background: #FFF}
// .themeTable tbody tr:hover {background: #ddd}
.newonr {
  padding: 3px 14px !important;
}

.fonthe {
  font-size: 16px !important;
  color: #464646 !important;
  margin-bottom: 15px !important;
  font-weight: 600;
}

.marisu {
  margin: 5px 0;
}

.circularprogress {
  .actual .MuiCircularProgress-svg {
    transform: rotate(180deg);
  }

  &.color-appts {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #2b6823;
    }
  }

  &.color-empre {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #f9bc0a;
    }
  }

  &.color-iss {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #2d7bf4;
    }
  }

  &.color-noti {

    .MuiTypography-root,
    .actual .MuiCircularProgress-svg {
      color: #21b792;
    }
  }
}

.paddnonoti {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 2px !important;
}

.paddal {
  padding-top: 7px !important;
}

.margne {
  margin-top: -3px !important;
}

.tabletd .themeTable tr td .themeCellContent {
  padding: 5px 0px !important;
  font-size: 16px !important;
  text-transform: capitalize;
  color: #333841 !important;
}

.boldfont {
  font-weight: 600 !important;
}

.resLinks {
  color: #5d6ff5 !important;
  text-decoration: underline !important;
}

.link {
  color: #1369ff !important;
  text-decoration: underline !important;
}

.link2 {
  color: #1369ff !important;
  text-decoration: underline !important;
  padding: 18px !important;
}

.issuelink {
  color: #5d6ff5 !important;
  padding: 10px 0 !important;
  text-decoration: underline !important;
}

/* table*/
.themeTable tbody tr:nth-child(even) {
  background: #efefef;
  margin-bottom: 12px !important;
}

.themeTable tbody tr:nth-child(odd) {
  background: #fff;
  margin-bottom: 12px !important;
}

.themeTable tbody tr:hover {
  background: #ddd;
}

tbody tr.transparent,
tbody tr.transparent:hover {
  background: transparent !important;
}

.themeTable>thead tr th.no-border {
  border-bottom: 0px solid rgba(00, 00, 00) !important;
  padding: 0px 5px;
}

.themeTable>thead tr th {
  border-bottom: 1px solid #dedede !important;
}

.tooltips {
  position: absolute;
  bottom: -30px;
  right: 0px !important;
  background-color: #efefef;
  color: black;
  padding: 5px;
  border-radius: 2px;
  white-space: nowrap;
  z-index: 10;
}

.tooltips::after {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 80px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #efefef transparent;
}

.tooltip {
  background-color: #fff;
}

.tooltip-heading {
  padding: 5px 10px;
  font-weight: 700;
  background-color: #eee;
  color: #464646;
}

.float-right {
  float: right;
}

.tooltip-container {
  padding: 2px 10px 5px 10px;
}

.padbtbut {
  padding-bottom: 100px;
}

.profilet {
  font-size: 1.25rem;
  font-family: Raleway, MarkOT, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  padding-bottom: 10px;
  line-height: 1.6;
  text-transform: uppercase;
  border-bottom: solid 1px #e9e9e9;
}

.themeTableContainer {
  overflow-x: auto;
}

.themeTable thead tr>th {
  background-color: #fff;
}

.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:first-child,
.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:last-child {
  background: #fff;
}

.themeTableContainer .themeTable tbody tr:nth-child(even)>td:first-child,
.themeTableContainer .themeTable tbody tr:nth-child(even)>td:last-child {
  background: #efefef;
}

.customtable .themeTable tbody tr:nth-child(even)>td:first-child,
.customtable .themeTable tbody tr:nth-child(even)>td:last-child {
  background: #fff;
}

.customtable .themeTable tbody tr:nth-child(odd)>td:first-child,
.customtable .themeTable tbody tr:nth-child(odd)>td:last-child {
  background: #fff;
}


.themeTableContainer tr>th:first-child,
.themeTableContainer tr>td:first-child {
  position: sticky;
  left: 0;
  border-right: 1px Solid #b9b9b9 !important;
}

.themeTableContainer th:nth-last-child(2),
.themeTableContainer td:nth-last-child(2) {
  position: sticky;
  right: 53px;
  border-left: 1px solid #b9b9b9 !important;
}

.themeTableContainer .themeTable tbody tr:nth-child(even)>td:nth-last-child(2) {
  background: #efefef;
}

.themeTableContainer .themeTable tbody tr:nth-child(odd)>td:nth-last-child(2) {
  background: #fff;
}

.themeTableContainer tr>th:last-child,
.themeTableContainer tr>td:last-child {
  position: sticky;
  right: 0;
  border-left: 1px Solid #b9b9b9 !important;
}

.chatbox {
  background-color: #efefef;
  padding: 0px;
  min-width: 100%;
  overflow-x: auto;
  position: relative;

  @media screen and (max-width:767px) {
    // width: 100% !important;
    background-color: #fff !important;
  }


  .chatboxcontianer {
    display: flex;
    background-image: url('../images/chat-bg.png');

    @media screen and (max-width:767px) {
      // position: fixed;
    }
  }

  .chat-accordian {
    box-shadow: none;

    &:before {
      background-color: transparent;
    }

    .MuiAccordionSummary-content {
      margin: 5px 0px;
      position: relative;

      &.Mui-expanded {
        margin-top: 5px;
      }

      .msgbadge {
        position: absolute;
        background-color: red;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        top: 10px;
        right: 20px;
      }
    }

    .MuiAccordionSummary-root {
      padding-left: 0px;
    }

    .MuiAccordionDetails-root {
      padding: 0px;
      flex-direction: column;
    }

  }

  .leftPanel {
    padding: 5px 0px;
    background-color: $white;
    overflow-y: auto;

    @media screen and (max-width:767px) {
      width: 100%;
      height: 80vh;
      padding: 0 !important;
      background-color: #fff !important;
    }

    @media (min-width:1451px) {
      height: 95vh;
    }

    .chanelHeader {
      padding: 5px 5px 0px 10px;
      color: $primary;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
    }


    .channleTitleBox {
      padding: 5px 5px 8px 10px;
      margin: 4px 0px;
      cursor: pointer;
      position: relative;

      @media screen and (max-width:767px) {
        background-color: #fff;
      }

      &.active {
        background-color: #efefef;
      }

      .msgbadge {
        position: absolute;
        background-color: red;
        border-radius: 50%;
        height: 6px;
        width: 6px;
        top: 10px;
        right: 20px;
      }

      .channelTitle {
        font-weight: 700;

        @media screen and (max-width:767px) {
          font-size: 16px;
          color: #333;
          line-height: 25px;
          font-weight: bold;
        }
      }

      .channelMessage {
        font-size: 12px;
        color: $gray-dark-color;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .rightPanel {
    position: relative;
    padding: 10px 10px;

    @media screen and (max-width:767px) {
      padding: 0px !important;
    }

    .msgBoxHeader {
      position: relative;
      padding-right: 50px;
      padding-bottom: 5px;

      @media screen and (max-width:767px) {
        padding: 20px 1px;
        background-color: #fff !important;
      }

      .peopleIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;

        @media screen and (max-width:767px) {
          top: 15px;
        }
      }

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;

        @media screen and (max-width:767px) {
          line-height: 10px;
        }
      }
    }

    .messageContainer {
      display: block;
      clear: both;
    }

    .msgBox {
      // border:1px Solid;
      overflow-x: auto;

      @media (min-width:771px) and (max-width:940px) {
        overflow: auto !important;
      }

      @media screen and (max-width:767px) {
        background-image: url('../images/chat-bg.png');
        background-color: #efefef;
      }

      .dtMsg {
        align-self: center;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: 700;

        .title {
          font-weight: 700;
          font-size: 15px;

          @media screen and (max-width: 767px) {
            font-weight: 750;
            font-size: 16px;
          }
        }
      }

      .nameBox {
        width: 40px;
        height: 40px;
        background-color: $primary;
        color: $white;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        background: linear-gradient(to bottom,
            adjust-color(scale-color($primary, $lightness: 15%), $saturation: 35%) 0%,
            adjust-color(scale-color($primary, $lightness: 12%), $saturation: 15%) 26%,
            $primary 100%);
        border-radius: 10px;
      }


      .msgTextBox {
        display: inline-block;
        min-width: 260px;
        max-width: 500px;
        // width: calc(100% - 130px);
        border: 1px Solid $grayish-white;
        border-radius: 4px;
        background-color: $white;
        flex-direction: column;
        padding: 3px;
        vertical-align: top;
        position: relative;
        padding: 8px 10px;

        @media screen and (max-width:767px) {
          max-width: 260px;
        }


        .dtDisplay {
          white-space: nowrap;
          font-size: 15px;
          color: $gray-color;
          font-weight: 500;
        }

        .imageDisplay {

          display: inline-flex;
          padding: 8px;

          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .textDisplay {
          margin-top: 5px;
          font-size: 15px;
          // min-width: 300px;
          width: 100%;
        }
      }


      .leftMsg {
        position: relative;
        float: left;

        @media screen and (max-width:767px) {
          padding-left: 12px;
        }

        .rigthMessagess {
          display: flex;
        }

        .nameBox {
          border-radius: 10px;
          margin-right: 10px;
        }

        .msgTextBox {

          &:before {
            content: " ";
            position: absolute;
            top: 8px;
            left: -10px;
            border-top: 5px solid transparent;
            border-right: 10px solid black;
            border-bottom: 5px solid transparent;
          }

          &:after {
            content: " ";
            position: absolute;
            top: 8px;
            left: -10px;
            border-top: 5px solid transparent;
            border-right: 10px solid white;
            border-bottom: 5px solid transparent;
          }
        }
      }

      .rightMsg {
        position: relative;
        float: right;

        @media screen and (max-width:767px) {
          padding-right: 12px;
        }

        .rigthMessagess {
          display: flex;
          flex-direction: row-reverse;
        }

        .nameBox {
          border-radius: 10px;
          margin-left: 10px;
          float: right;
        }

        .msgTextBox {
          float: right;
          background-color: #f6f8f9;

          .dtDisplay {
            white-space: nowrap;
            font-size: 14px;
            color: $gray-color;
            font-weight: 500;
          }

          .textDisplay {
            font-size: 14px;
            // min-width: 230px;
          }

          &:before {
            content: " ";
            position: absolute;
            top: 8px;
            right: -10px;
            border-top: 5px solid transparent;
            border-left: 10px solid $black;
            border-bottom: 5px solid transparent;
          }

          &:after {
            content: " ";
            position: absolute;
            top: 8px;
            right: -10px;
            border-top: 5px solid transparent;
            border-left: 10px solid $white;
            border-bottom: 5px solid transparent;
          }
        }
      }
    }

    .msgBoxsender {
      border-top: 1px solid $gray-bg;
      flex-direction: row;
      display: flex;
      padding-top: 15px;
      margin-top: 5px;

      @media screen and (max-width:767px) {
        background-color: #fff !important;
        margin-bottom: 15px;
      }

      .selectedImage {
        position: absolute;
        bottom: 102px;
        background-color: #fafafa;
        width: 90%;
        padding: 5px;
        display: flex;

        .addmoreimg {
          color: #fff;
          background-color: #464646;
          width: 25px;
          padding: 0px;
          border-radius: 4px;
          height: 100%;
        }

        .imgBox {
          position: relative;
          width: 50px;
        }

        .progressBar {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0px;
          background-color: #ffffff77;
        }

        .imgBoxClearIcon {
          cursor: pointer;
          position: absolute;
          top: 0px;
          background-color: #fefefe;
          border: 1px Solid #aaaaaa;
          height: 15px;
          width: 15px;
          color: #ff0000;

          left: 0px;
        }
      }

      .msgTextbox {
        width: calc(100% - 42px);
        border: 1px solid $gray-light-color;
        border-radius: 4px;
        padding: 5px;
      }

      .buttonContainer {
        width: 50px;

        .viewInputGallery {
          opacity: 0;
          position: absolute;
          z-index: -1;
          left: 10px;
          width: 30px;
        }

        button {

          width: 42px;
          height: 42px;
          color: $white;
          border-radius: 4px;

          &.sendbtn {
            background-color: $primary;
            margin: 0px 0px 2px 4px;
          }

          &.imgbtn {
            background-color: $black;
            margin: 2px 0px 2px 4px;
          }
        }
      }
    }

    .noChannSelected {
      display: flex;
      height: 100%;
      font-size: 20px;
      font-weight: 700;
      color: $gray-light-color;
      justify-content: center;
      align-items: center;
    }
  }
}

.dateFieldInput input {
    height: 40px !important;
    padding: 0px 0px 0px 5px;
}

/* calendar.css */
.calendar-container {
  max-width: 100%;
  // margin: 0 auto;
  @media screen and (max-width:767px) {
    max-width: 390px;
  }
  // @media (min-width:1951px) {
  //   max-width: 2220px !important;
  // }
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media (min-width:768px) {
    position: relative;
  }
}

.calendar-nav {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.calendar-NavControl {
  display: flex;

  @media (min-width:768px) {
    position: sticky;
    right: 20px;
  }
}

.calendar-title {
  font-size: 1.25rem;
  font-weight: 600;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.calendar-controls {
  display: flex;
  // align-items: center;
}

.employee-select {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background-color: white;
}

.view-toggle {
  display: flex;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}

.view-toggle-btn {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  // transition: all 0.2s;
}

.view-toggle-btn.active {
  background-color: #078037;
  color: white;
}
.view-toggle-btn:hover {
  background-color: #078037 !important;
  color: white;
}

.view-toggle-btn:not(:last-child) {
  border-right: 1px solid #d6d1d1;
}

.calendar-grid-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.weekday-header {
  text-align: center;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 14px;
  color: #078037;
  border: 0.5px solid #ddd;
}

.calendar-day {
  height: 7rem;
  min-height: 7rem;
  border: 0.5px solid #ddd;
  padding: 0px 8px 8px 8px;
  overflow-y: scroll;
  scrollbar-width: none;
  position: relative;
  @media screen and (max-width:767px) {
    height: 3rem;
    min-height: 3rem;
  }
}

.calendar-day-null {
  border: 0.5px solid #ddd;
  padding: 0px 8px 8px 8px;
  height: 7rem;
  min-height: 7rem;
  overflow-y: scroll;
  scrollbar-width: none;
  @media screen and (max-width:767px) {
    height: 3rem;
    min-height: 3rem;
  }
}

.light-color {
  color: #9ca3af;
}

.calendar-day-number {
  font-weight: 500;
  background-color: white;
  height: 15px;
  width: 130px;
  @media screen and (max-width: 767px) {
    width: 25px;
  }
  // @media (min-width:1951px) {
  //   width: 85%;
  // }
}

.appointment {
  margin-top: 8px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.view-placeholder {
  border: 0.5px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
}

.appointmentCount {
  color: #078037;
  font-size: 12px;
  font-style: italic;
  width: 45%;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin-top: 6px;
    margin-left: 15px;
  }
  // @media (min-width:1951px) {
  //   width: 35%;
  // }
}

.clientLable{
  font-size: 14px;
  font-weight: 500;
  color: #707070;
  overflow: hidden !important;
  overflow-wrap: anywhere;
  @media (min-width:1951px) {
    font-size: 16px;
    margin-left: 10px;
  }
}

.clientTime {
  @media (min-width:1951px) {
    font-size: 16px;
  }
}

.navButtons {
  box-shadow: none !important;
  padding: 8px 16px !important;
  border-radius:0px !important ;
}

.arrowIcon {
  @media screen and (max-width: 767px) {
    font-size: 18px !important;
  }
}

/* WeekView.css */

.week-view-container {
  max-width: 100%;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.nav-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-button {
  border: 1px solid #ddd;
  background: white;
  padding: 5px 10px;
  cursor: pointer;
}

.week-title {
  font-size: 18px;
  font-weight: bold;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.employee-select {
  padding: 5px;
  border: 1px solid #ddd;
}

.view-toggle {
  display: flex;
  border: 1px solid #b9b6b6;
}

.view-toggle button {
  padding: 5px 10px;
  background: white;
  border: none;
  border-right: 1px solid #b9b2b2;
}

.view-toggle button:last-child {
  border-right: none;
}

.view-toggle button.active {
  background-color: #078037;
  color: white;
}

.calendar-grid {
  border: 1px solid #ddd;
  overflow-x: auto;
}

.grid-row {
  display: grid;
  grid-template-columns: 80px repeat(28, minmax(200px, 1fr));
}
.grid-row-day {
  display: grid;
  grid-template-columns: 80px repeat(28, minmax(200px, 1fr));
}
.grid-row-Time {
  display: grid;
  grid-template-columns: 80px repeat(28, minmax(200px, 1fr));
}

.time-column {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 2px solid #ddd;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  height: 100px;
  width: 80px;
}

.day-header {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
}

.timezone-header {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
  font-weight: 550;
  color: #555555;
}

.time-slot {
  border: 1px solid #ddd;
  min-height: 100px;
  position: relative;
}

.appointmentView {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0px 2px;
  padding: 10px;
  background-color: #078037;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  z-index: 1;
  box-sizing: border-box;
  overflow-x: auto;
  scrollbar-width: none;
}

.appointmentView-title {
  font-weight: 800;
  font-size: 16px;
}

.appointmentView-details {
  font-size: 15px;
}
.appointmentView-details2 {
  font-size: 15px;
  font-weight: 300;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls {
    width: 100%;
    flex-wrap: wrap;
  }
}

.daily-header {
  text-align: center;
  font-weight: 550;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ddd;
}

.highlight-row {
  border-top: 1px solid red;
}

.appointmentView.redline {
  border: 2px solid red;
}

.containers {
   @media (min-width:1001px) {
    max-width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  } 
}
.appointmentDateCount {
  display: flex;
  justify-content: end;
  position: sticky;
  top: 0;
  backdrop-filter: blur(8px);
  @media screen and (max-width:767px) {
     flex-direction: column;
   }
  @media (min-width:1951px) {
     justify-content: flex-start;
     width: 100%;
   }
}

.profile {
  background: #343a45;
  top: 0;
  position: fixed;
  z-index: 1;
  width: 237px;
}

.logoutLink {
  background-color: #343a45;
  width: 220px;
  @media screen and (max-width:767px) {
   padding: 0px;
  }
}

.supplyBadge{
  position: relative;
  right: 10px;
}

.drawerWidth {
  width: 240px;
}

.updateDialog {
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
  width: 100%;
}

.updateDialogName {
  width: 35%;
  font-weight: 550;
}

.updateDialogLabel {
  width: 65%;
  font-weight: 700;
}

.dayExtraWidth {
  width: fit-content;
}

.dialogTitle {
  font-size: 22px;
  font-weight: 800;
  margin-left: 12px;
  padding: 5px 22px;
}

.dialogCancelTitle {
  font-size: 22px;
  color: red;
  font-weight: 800;
  margin-left: 12px;
  padding: 5px 22px;
}

.newTimeColumn{
  position: sticky;
  left: 0;
  background-color: #ffffff;
  z-index: 4;
}

.shift-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1024px) {
  .shift-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}