.listItem {
    margin-top: 12px;
    border-bottom: 1px solid #dbdbdb;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 12px !important;
}

.timeZone {
    color: #000 !important;
}

.tooltip-heading2 {
    padding: 5px 10px;
    font-weight: 700;
}

.ddminWidth {
    width: 150px !important;

    @media screen and (max-width:767px) {
        width: 320px !important;
    }
    @media (min-width:771px) and (max-width:900px) {
        width: 500px !important;
    }
}

.ddminWidth input {
    width: 100% !important;
}

.tooltip-container2 {
    padding: 2px 10px 5px 10px;
}

.multicontainer {
    margin-left: 0px !important;
}

.listItem:last-child {
    border-bottom: none !important;
}

.Action {
    color: #096414 !important;
}

.timeZone {
    text-decoration: underline !important;
}

.dashboardeditSave {
    color: #078037;
    margin-right: 5px;
    width: 0.8em !important;
    height: 0.8em !important;
}

.dashboardremove {
    color: #cc1716;
    margin-right: 5px;
    width: 0.8em !important;
    height: 0.8em !important;
}

.summaryTableWrap {


    .stausCircle {
        width: 8px;
        height: 8px;
        border-radius: 20%;
        margin: 5px;
    }

    .statusYellow {
        background-color: #ebdd41;
    }

    .statusYellowcolo {
        color: #ebdd41 !important;
        display: inline-flex;
    }

    .statusGreen {
        background-color: #078037;
    }

    .statusOrange {
        background-color: #ff7e00;
    }

    .statusRedcolo {
        color: #cc1716 !important;
        display: inline-flex;
    }

    .statusRed {
        background-color: #cc1716;
    }
}

.even {
    background-color: white;
}
  
.odd {
    background-color: #efefef;
}